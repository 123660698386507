<template>
  <BaseLayout>
    <template #header>
      <h2>{{ (listType === 'reaktif' ? 'Darah Reaktif' : '') ||  (listType === 'greyzone' ? 'Grey Zone' : '') || (listType === 'non-reaktif' ? 'Darah Non Reaktif' : '') }}</h2>
      <div class="d-flex justify-content-between">
        <div class="row align-items-center">
          <p class="hp-p1-body mb-0 mx-3 text-black">
            Semua ({{ table.total }})
          </p>
        </div>
        <div class="w-15 align-items-center">
          <div class="position-relative">
            <b-input-group class="hp-input-group-search custom-input-height">
              <b-form-input
                v-model="table.search"
                type="search"
                placeholder="Cari Berdasarkan Kode"
                class="custom-input-height search-input"
                @input="debounceSearch"
              />
              <i class="ri-search-line remix-icon text-primary search-icon" />
            </b-input-group>
          </div>
        </div>
      </div>
    </template>
    <template #content>
      <b-table
        style="overflow: auto; white-space: nowrap"
        :items="table.items"
        :fields="computedFields"
        :busy.sync="table.loading"
        responsive="sm"
        show-empty
      >
        <template #cell(status)="row">
          <b-badge
            :variant="row.item.status === 'non-reaktif' ? 'success' : row.item.status === 'reaktif' ? 'danger' : row.item.status === 'greyzone' ? 'light': '' "
          >
            {{ row.item.status === 'non-reaktif' ? 'Non Reaktif' : row.item.status === 'reaktif' ? 'Reaktif' : row.item.status === 'greyzone' ? 'Grey Zone': '' }}
          </b-badge>
        </template>

        <template
          #cell(syphilis)="row"
        >
          <p>{{ row.item.syphilis || 'Neg' }}</p>
        </template>

        <template
          #cell(hepatitis_b)="row"
        >
          <p>{{ row.item.hepatitis_b || 'Neg' }}</p>
        </template>

        <template
          #cell(hepatitis_c)="row"
        >
          <p>{{ row.item.hepatitis_c || 'Neg' }}</p>
        </template>

        <template
          #cell(hiv_aids)="row"
        >
          <p>{{ row.item.hiv_aids || 'Neg' }}</p>
        </template>

        <template #table-busy>
          <div class="hp-p1-body text-center my-2">
            <b-spinner class="align-middle mr-8" />
            <strong>Memuat...</strong>
          </div>
        </template>

        <template #empty>
          <p class="hp-p1-body text-center">
            Tidak Ada Data
          </p>
        </template>
      </b-table>
      <b-pagination
        v-model="table.currentPage"
        class="mb-3"
        align="right"
        :per-page="table.perPage"
        :total-rows="table.total"
        @input="changePage"
      />
    </template>
  </BaseLayout>

</template>

<script>
import tableDataMixins from '@/mixins/tableDataMixins'
import {
  BBadge, BFormGroup, BFormInput, BFormSelect, BInputGroup, BPagination, BSpinner, BTable,
} from 'bootstrap-vue'
import ScreeningAPI from '../../../../../api/screening/screeningAPI'
import {EventBus} from "../../../../../helpers/eventBus";

export default {
  name: 'TableListIMLTD',
  components: {
    BFormInput,
    BInputGroup,
    BPagination,
    BSpinner,
    BBadge,
    BTable,
  },
  mixins: [tableDataMixins],
  props: {
    listType: {
      type: String,
      default: 'non-reaktif',
    },
  },
  computed: {
    computedFields() {
      // Default fields shown for both list types
      const fields = [
        { key: 'id', label: 'No' },
        { key: 'barcode', label: 'Barcode' },
        { key: 'created_at', label: 'Tanggal' },
        { key: 'officer_name', label: 'Nama Petugas' },
        { key: 'status', label: 'Status' },
      ]

      // Add additional fields for 'reaktif' listType
      if (this.listType === 'reaktif' || this.listType === 'greyzone') {
        fields.push(
          { key: 'syphilis', label: 'Syphilis' },
          { key: 'hiv_aids', label: 'HIV' },
          { key: 'hepatitis_b', label: 'Hbsag' },
          { key: 'hepatitis_c', label: 'HCV' },
        )
      }

      return fields
    },
  },
  mounted() {
    this.fetchData()
  },
  created() {
    // Listen for the 'reloadData' event
    EventBus.$on('reloadData', this.fetchData)
  },
  beforeDestroy() {
    // Clean up the event listener when the component is destroyed
    EventBus.$off('reloadData', this.fetchData)
  },
  methods: {
    async fetchData() {
      try {
        this.table.loading = true

        const params = {
          page: this.table.currentPage,
          per_page: this.table.perPage,
          search: this.table.search,
          status: (this.listType === 'reaktif' ? 'reaktif' : '') || (this.listType === 'non-reaktif' ? 'non-reaktif' : '') || (this.listType === 'greyzone' ? 'greyzone' : ''),
        }

        const { data: response } = await ScreeningAPI.getAll({
          params,
        })

        this.table.items = response.data.data
        this.table.total = response.data.total
      } catch (e) {
        console.error(e)
      } finally {
        this.table.loading = false
      }
    },
    // fungsi untuk paginations dan search
    changePage(page) {
      this.table.currentPage = page
      this.fetchData()
    },
    debounceSearch() {
      clearTimeout(this.table.debounceTimer)
      this.table.debounceSearch = setTimeout(() => {
        this.onChangeSearchTable()
      }, 500)
    },
    onChangeSearchTable() {
      this.fetchData()
    },
  },
}
</script>

<style scoped>
/* kostumisasi ukuran dari input search */
.custom-select-height,
.custom-input-height {
  height: 38px;
}

.custom-button-height {
  height: 100%;
}

.hp-input-group-search {
  display: flex;
  align-items: center;
}

/* posisi relatif untuk menempatkan icon pencarian kedalam input */
.position-relative {
  position: relative;
}

.search-input {
  width: 100%;
  padding-left: 30px;
  height: 38px;
  border: 1px solid #ced4da;
  border-radius: 5px;
}

.search-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  color: #007bff;
}
</style>
