<template>
  <div>
    <BaseLayout>
      <template #header>
        <h1 class="text-uppercase">
          imltd
        </h1>
        <h2>Darah Non-Reaktif</h2>
      </template>
      <template #content>
        <b-row class="d-flex justify-content-center">
          <b-col cols="auto">
            <div class="table-wrapper w-100 mx-auto overflow-scroll">
              <barcode-checker
                  v-model="samplesCollected"
                  :api-service="apiService"
                  @barcode-added="handleBarcodeAdded"
              />
            </div>
          </b-col>
        </b-row>
        <div class="d-flex justify-content-end">
          <b-button
              variant="primary"
              class="mt-3 mb-3"
              @click="postCollectedNonReactiveSamples"
          >
            <b-spinner
                v-if="isLoading"
                small
            />
            Simpan
          </b-button>
        </div>
      </template>
    </BaseLayout>
    <b-row class="d-flex justify-content-center mt-5">
      <b-col cols="auto">
        <TableListIMLTD list-type="non-reaktif" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BButton, BSpinner,
} from 'bootstrap-vue'
import ScreeningAPI from '@/api/screening/screeningAPI'
import BarcodeChecker from '@/components/shared/BarcodeChecker.vue'
import TableListIMLTD from "./components/TableListIMLTD.vue";
import {EventBus} from "../../../../helpers/eventBus";

export default {
  name: 'ListScreeningNonReactiveSample',
  components: {
    BRow,
    BCol,
    BButton,
    BSpinner,
    BarcodeChecker,
    TableListIMLTD,
  },
  data() {
    return {
      isLoading: false,
      isBusy: false,
      // data untuk memastikan barcode memiliki jeda dalam menampilkan data
      debounceCheckedBarcode: null,
      // tampilan nomor pada tabel
      counter: 0,
      // form data yang akan dikirimkan
      formData: {
        barcode: '',
      },
      // daftar fields untuk table non-reaktif
      fields: [
        {
          key: 'no',
          label: 'No',
          sortable: true,
        },
        {
          key: 'barcode',
          label: 'Barcode',
          sortable: false,
        },
        {
          key: 'blood_bag_number',
          label: 'Nomor Selang',
          sortable: true,
        },
        {
          key: 'blood_type',
          label: 'Golongan Darah',
        },
        {
          key: 'bag_type',
          label: 'Jenis Kantong',
        },
      ],
      // untuk menampilkan data items pada table non-reactive
      items: [],
      // data untuk menampung sample non-reaktif
      samplesCollected: [],
    }
  },
  methods: {
    apiService(payload) {
      return ScreeningAPI.barcodeScreening(payload)
    },
    handleBarcodeAdded(newBarcode) {
      this.samplesCollected = newBarcode
    },
    async postCollectedNonReactiveSamples() {
      this.isLoading = true

      try {
        const uniqueBarcodes = [...new Set(this.samplesCollected)]

        const payload = {
          barcodes: uniqueBarcodes,
        }

        const response = await ScreeningAPI.addScreeningNonReactive(payload)

        this.$bvToast.toast(response.data.message, {
          title: 'Data Berhasil Dikirim',
          variant: 'success',
          solid: true,
        })

        EventBus.$emit('clearData')
        EventBus.$emit('reloadData')
      } catch (error) {
        this.$bvToast.toast(error.response?.data?.message || 'Terjadi kesalahan', {
          title: 'Data Gagal Dikirim',
          variant: 'danger',
          solid: true,
        })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
<style scoped>
  .table-wrapper {
    max-height: 600px;
  }

  .overflow-scroll {
    overflow-y: auto;
  }
</style>
