<template>
  <div>
    <b-form-group
      label="Masukkan Barcode"
      label-for="input-barcode"
    >
      <b-form-input
        id="input-barcode"
        v-model="barcode"
        type="text"
        @input="debounceCheckedBarcode"
      />
    </b-form-group>

    <b-table
      :items="items"
      :fields="fields"
      :busy.sync="isBusy"
      show-empty
      striped
      hover
    >
      <template #empty>
        <div class="hp-p1-body text-center my-2">
          <strong>Tidak Ada Data</strong>
        </div>
      </template>
      <template #table-busy>
        <div class="hp-p1-body text-center my-2">
          <b-spinner class="align-middle mr-8" />
          <strong>Memuat...</strong>
        </div>
      </template>
      <template #cell(action)="{ index }">
        <b-button
          variant="danger"
          size="sm"
          class="mr-1"
          @click="removeItem(index)"
        >
          <i class="ri-delete-bin-6-line" />
        </b-button>
      </template>
    </b-table>
  </div>
</template>
<script>
import {
  BFormGroup, BFormInput, BTable, BSpinner, BButton,
} from 'bootstrap-vue'
import { debounce } from 'lodash'
import mapBloodType from '@/utils/mapBloodType'
import { EventBus } from '../../helpers/eventBus';

export default {
  name: 'BarcodeChecker',
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BTable,
    BSpinner,
  },
  props: {
    apiService: {
      type: Function,
      required: true,
    },
    mode: {
      type: String,
      default: 'many',
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      barcode: '',
      isBusy: false,
      counter: 0,
      fields: [
        { key: 'no', label: 'No', sortable: true },
        { key: 'barcode', label: 'Barcode', sortable: false },
        { key: 'blood_bag_number', label: 'Nomor Selang', sortable: true },
        { key: 'blood_type', label: 'Golongan Darah' },
        { key: 'bag_type', label: 'Jenis Kantong' },
        { key: 'action', label: 'Aksi' },
      ],
      items: [],
      samplesCollected: this.value,
      debounceCheckedBarcode: null,
    }
  },
  created() {
    this.debounceCheckedBarcode = debounce(this.checkingBarcodes, 300)
  
      // Listen for the 'clearData' event
      EventBus.$on('clearData', this.clearItem)
  },
  beforeDestroy() {
    // Clean up the event listener when the component is destroyed
    EventBus.$off('reloadData', this.clearItem)
  },
  methods: {
    async checkingBarcodes() {
      if (this.isBusy || !this.barcode.trim()) return

      this.isBusy = true

      try {
        const response = await this.apiService({ barcode: this.barcode })

        if (response && response.data) {
          // jika data null dan code 400
          if (response.data.code === 400 && response.data.data === null) {
            this.$bvToast.toast(response.data.message, {
              title: 'Terjadi Kesalahan',
              variant: 'danger',
              solid: true,
            })

            return
          }

          if (response.data.data) {
            const tableItems = {
              no: this.counter + 1,
              barcode: response.data.data.barcode,
              blood_bag_number: response.data.data.blood_bag_number,
              blood_type: mapBloodType(response.data.data.blood_type),
              bag_type: response.data.data.bag_type,
            }

            const existsBarcode = this.items.some(item => item.barcode === tableItems.barcode)

            if (!existsBarcode) {
              if (this.mode === 'onlyOne') {
                this.items = [tableItems]
                this.samplesCollected = tableItems.barcode
              } else {
                if (!this.samplesCollected.includes(tableItems.barcode)) {
                  this.items.push(tableItems)
                }
                this.samplesCollected.push(tableItems.barcode)
              }
              this.counter++
              this.$emit('barcode-added', this.samplesCollected)
              // this.$emit('input', this.samplesCollected)
            } else {
              this.$bvToast.toast('Barcode sudah ada', {
                title: 'Peringatan',
                variant: 'warning',
                solid: true,
              })
            }

            this.items.forEach((item, index) => {
              item.no = index + 1
            })

            this.barcode = ''
          }
        } else {
          this.handleError(new Error('Format respons tidak valid'))
        }
      } catch (e) {
        this.handleError(e)
      } finally {
        this.isBusy = false
      }
    },
    handleError(error) {
      this.$bvToast.toast(error.response.message, {
        title: 'Terjadi Kesalahan',
        variant: 'danger',
        solid: true,
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.samplesCollected.splice(index, 1)
    },
    clearItem() {
      this.items = []
    }
  },
}

</script>
