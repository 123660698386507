function mapBloodType(bloodType) {
  const mapping = {
    'A+': 'A Pos',
    'A-': 'A Neg',
    'B+': 'B Pos',
    'B-': 'B Neg',
    'AB+': 'AB Pos',
    'AB-': 'AB Neg',
    'O+': 'O Pos',
    'O-': 'O Neg',
  }

  if (!bloodType) {
    return '-'
  }

  return mapping[bloodType] || bloodType
}

export default mapBloodType